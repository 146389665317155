<template>
  <div>
    resgates
  </div>
</template>

<script>
export default {

}
</script>
